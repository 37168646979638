.container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 0px 30px;
}

.attachment {
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove {
  width: 25px;
  height: 25px;
  top: 10px;
  right: 10px;
  background-size: contain;
  background-image: url('../../img/icons/remove.svg');
  position: absolute;
  background-repeat: no-repeat;
}
.remove:hover {
  cursor: pointer;
}
.overlay {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 5px;
  position: absolute;
  border-radius: 4px;
}

.full {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img, .video {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.placeholderImg {
  z-index: 999;
  display: block;
  position: relative;
  max-width: 30px;
}

.loading {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url('../../img/icons/loader-w.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
