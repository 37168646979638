.button {
  display: inline-flex;
  position: relative;

  padding: 0 25px;
  font-weight: 700;
  height: 46px;

  border: 1px solid transparent;
  border-radius: 5px;

  cursor: pointer;

  font-size: 16px;
  line-height: 45px;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;

  background-color: #211147;
  color: #fff;
  transition: 180ms;
  transition-property: border-color, background-color, color, opacity;
}
.button:focus {
  outline-color: black;
}
.button:hover {
  color: #fff;
  background-color: #382473;
}

.button.white {
  border: 1px #979797 solid;
  background-color: #ffffff;
  color: #0a0a0a;
}

.button.white:hover {
  background-color: #fafafa;
}

.button.light-purple {
  color: #0a0a0a;
  border: 1px #979797 solid;
  background-color: #ffffff;
}
.button.black {
  color: #fff;
  background-color: #1f1f1f;
}
.button.black:hover {
  background-color: #323232;
}
.button.light-purple:hover {
  background-color: #e9e8ed;
}

.button.gray {
  background-color: #d8d8d8;
  color: #858585;
}

.button.gray:hover {
  background-color: #c0c0c0;
  color: #858585;
}

.button.green {
  background-color: #3ab97a;
}

.button.green:hover {
  background-color: #48ca84;
}

.button.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.button + .button {
  margin-left: 15px;
}
.button.grow {
  width: 100%;
  justify-content: center;
}
.button.uppercase {
  text-transform: uppercase;
}
@media (max-width: 560px) {
  .button {
    padding: 0 20px;
    height: 42px;
    line-height: 41px;
  }
}

.visir.button {
  text-transform: uppercase;
  border-radius: 3px;
}
.visirPrimary.button {
  background-color: var(--purple-500);
  color: #fff;
}
.visirPrimary.button:hover {
  background-color: var(--purple-600);
  color: #fff;
}
.visir a:hover {
  color: #858585;
}
