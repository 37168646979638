.footer {
  background-color: #130a2b;
}
.footerContainer section {
  padding: 20px 0;
  max-width: none;
}
.footerColumn {
  margin-bottom: 0;
}
@media(max-width: 790px) {
  .footerColumn {
    margin-bottom: 40px;
  }
}
