.navContainer {
  border-bottom: 1px var(--border-color) solid;
  position: relative;
  padding-bottom: 1.25rem;
  margin-bottom: 2rem;
}
.title {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.btn {
  background: none;
  border: none;
  font-weight: 700;
  text-transform: uppercase;
  color: #313131;
  padding: 0;
  margin-right: 1.5rem;
  position: relative;
}
.btn:hover {
  color: var(--purple-500);
}
.btn.active {
  color: var(--purple-500);
}
.btn.active:after {
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: var(--purple-500);
  content: '';
  left: 0;
  bottom: calc(-1.25rem - 1px);
}
.metaText {
  color: #999999;
  position: relative;
  top: 2px;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem !important;
}
.right {
  float: right;
  margin-right: 0;
  position: relative;
}
@media(max-width: 620px) {
  .btn {
    font-size: 0.875rem;
    margin-right: 1rem;
  }
  .metaText {
    display: none;
  }
  .right {
    top: 2px;
  }
}