.container {
  margin-bottom: 2rem;
}
.container h2 {
  font-size: 1.5rem;
}
.info {
  color: var(--gray-500);
  font-size: 0.875rem;
  text-align: center;
  margin-top: 0.5rem;
}
.btn, .manageBtn {
  margin-top: 1rem;
}
