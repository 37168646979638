.container {
  margin-bottom: 2rem;
  margin-top: -2.3rem;
  background-color: var(--red-500);
  border: 1px var(--red-500) solid;
  border-top: none;
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  font-weight: 600;
  border-radius: 0 0 0.5rem 0.5rem;
}
.remove {
  color: #fff !important;
}
.remove:hover {
  text-decoration: underline !important;
}
