.right {
  background-image: url('https://pardus-prod.imgix.net/logo/tal-header.png?w=810');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}
@media(max-width: 992px) {
  .right {
    background-image: none;
  }
}
