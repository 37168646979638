.container {
  height: 80px;
  z-index: 999;
  color: #fff;
  position: relative;
  background-color: #211147;
}

.transparent {
  background-color: transparent;
}

.content {
  display: flex;
  align-items: center;
  height: 80px;
}

.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  border-radius: 8px;
}

.pardus {
  font-weight: 700;
  font-size: 26px;
  color: #fff;
  margin-left: 10px;
}

.menu {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu a {
  color: #fff;
  text-decoration: none;
  padding: 0 20px;
}

.menu a:hover {
  color: #fff;
  text-decoration: underline !important;
}

.active {
  font-weight: 700;
  color: #fff !important;
}

.mobileMenu {
  display: none;
}

.menuIcon, .closeIcon {
  width: 25px;
  height: 18px;
  background-image: url('../../img/icons/list.svg');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 9999;
}

.closeIcon {
  position: fixed;
  top: 25px;
  opacity: 0;
  right: 25px;
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 0.5s;
  background-image: url('../../img/icons/cancel.svg');
}

.mobileMenuContent {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  background-color: #211147;
  z-index: 1;
  animation: appear 0.5s ease-in-out forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobileMenuContent a {
  display: block;
  font-size: 24px;
  margin: 10px 0;
  color: #fff;
  text-decoration: none;
}

.mobileMenuContent a.active:hover {
  color: #fff;
  text-decoration: underline !important;
}

.mobileMenuContent a:hover {
  color: #fff;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@media(max-width: 992px) {
  .content {
    height: 70px;
    justify-content: space-between;
  }

  .mobileMenu {
    display: block;
  }

  .container {
    background-color: #211147;
    height: 70px;
  }

  .container.transparent {
    background-color: transparent;
  }
  .pardus {
    font-size: 18px;
  }
  .logo img {
    height: 40px;
    border-radius: 8px;
  }
  .menu {
    display: none;
  }
}
