.flex {
  display: flex;
  min-height: 100vh;
  margin: 0 2rem;
}

.left {
  min-width: 420px;
  background-color: #170e33;
  background-image: url('../../img/phone.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 280px;
}

.register {
  color: #6e6e6e;
  text-align: right;
  padding-top: 20px;
  padding-right: 20px;
}
.registerLink,
.forgotLink {
  display: inline-block;
  color: #0a0a0a;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.registerLink:hover,
.forgotLink:hover {
  color: #696969;
}
.right {
  width: 100%;
  position: relative;
}

.loginContent h1 {
  margin-bottom: 0.5rem;
}
.loginContainer {
  max-width: 580px;
  margin: 0 auto;
  margin-top: 3rem;
}
.actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions > button {
  margin: 0 !important;
}
.privacy {
  margin: 20px 0;
  position: relative;
}

.privacy label {
  position: relative;
  left: 5px;
  cursor: pointer;
  user-select: none;
}

.title {
  font-weight: 500;
  font-size: 42px;
  line-height: 68px;
}

.inputs {
  margin-top: 20px;
}

.input {
  margin-top: 1.5rem;
}
@media (max-width: 1024px) {
  .left {
    display: none;
  }
}
