.container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.left {
  display: flex;
}
.name {
  font-weight: 800;
}
.info {
  padding-right: 20px;
}
.meta {
  color: #999999;
  display: flex;
  gap: 6px;
}
.img {
  margin-right: 1rem;
  flex-shrink: 0;
  top: 4px;
  position: relative;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem;
}
.btn {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  background-image: url('../../img/icons/v-play.svg');
  background-repeat: no-repeat;
}
.btn.playing {
  background-image: url('../../img/icons/v-pause.svg');
}
.episodePlaying .name {
  color: var(--purple-500) !important;
}
.locked {
  cursor: default;
}
.locked .btn {
  background-image: url('../../img/icons/lock.svg');
  background-size: contain;
}
