.hideMobile {
  display: block;
}
.eventBtn {
  position: relative;
  top: -80px;
  margin-bottom: -80px;
  width: 100%;
  z-index: 1;
  animation: slideDown 0.2s ease-in-out forwards;
}
@media(max-width: 991px) {
  .hideMobile {
    display: none;
  }
  .episodesContainer {
    margin-bottom: 3rem;
  }
}

@keyframes slideDown {
  0% {
    margin-bottom: -80px;
    top: -80px;
  }
  100% {
    top: 0;
    margin-bottom: 0;
  }
}
