.label {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  transition: font-size 0.1s ease-in-out;
}

.hide {
  display: none;
}

.selectImage {
  cursor: pointer;
  border: 1px #ddd dashed;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
}

.selectImage:hover {
  background-color: #f8f8f8;
}

.preview img {
  width: 150px;
  border-radius: 10px;
  cursor: pointer;
}
