.price {
  font-size: 1.5rem;
  font-weight: 800;
}
.withBorder {
  padding-top: 2.5rem;
  border-top: 1px var(--border-color) solid;
  margin-top: 1rem;
}
.price span {
  font-size: 0.875rem !important;
  color: var(--gray-500);
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 400;
}
