.actions {
  margin-top: 20px;
}
.isOpen {
  margin-top: 20px;
}
.disclaimer {
  color: var(--red-500);
  margin-top: 12px;
  font-weight: bold;
}
