.container {
  margin-top: 20px;
}

.container h1 {
  padding-left: 20px;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 700;
}

.totalSales {
  margin: 20px 0;
  border: 1px #ececec solid;
  padding: 15px 20px;
  text-align: center;
}
