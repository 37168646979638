.flex {
  display: flex;
  min-height: 100vh;
}

.left {
  min-width: 420px;
  background-color: #170e33;
  background-image: url('../img/phone.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 280px;
}

.register {
  color: #6e6e6e;
  text-align: right;
  padding-top: 20px;
  padding-right: 20px;
}
.registerLink,
.forgotLink {
  display: inline-block;
  color: #0a0a0a;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.registerLink:hover,
.forgotLink:hover {
  color: #696969;
}
.right {
  width: 100%;
  position: relative;
}

.loginContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.loginContent {
  position: absolute;
  min-width: 480px;
  top: calc(50% - 40px);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.actions {
  margin-top: 20px;
  text-align: right;
}

.privacy {
  margin: 20px 0;
  position: relative;
}

.privacy label {
  position: relative;
  left: 5px;
  cursor: pointer;
  user-select: none;
}

.title {
  font-weight: 500;
  font-size: 42px;
  line-height: 68px;
}

.inputs {
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .left {
    display: none;
  }
  .loginContainer {
    max-width: 520px;
    left: 50%;
    transform: translateX(-50%);
  }
  .loginContent {
    top: 60px;
    left: 0;
    padding: 0 20px;
    transform: translateX(0) translateY(0);
    min-width: 100%;
  }
}
