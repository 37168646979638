.container {
  margin-top: 30px;
}
.categoryItem {
  cursor: pointer;
  background-color: #dddddd;
  border: 1px #b5b5b5 solid;
  color: #3f3e3e;
  padding: 7.5px 15px;
  border-radius: 10px;
}

.categoryItem:hover {
  background-color: #d7d7d7;
}

.categoryItem + .categoryItem {
  margin-left: 15px;
}

.count {
  color: #9e9e9e;
}

.christmas {
  color: #fff;
  background-color: #f44e4e;
  border: 1px #f44e4e solid;
  font-weight: 700;
}

.christmas:hover {
  background-color: #e22f2f;
}

.categoryItem.christmas.active {
  background-color: #e22f2f;
  border: 1px #e22f2f solid;
}

.categoryItem.active {
  background-color: #211147;
  border: 1px #211147 solid;
  color: #fff;
  font-weight: 700;
}

.categoryItem.active .count {
  color: #fff;
}

.hideMobile {
  display: block;
}

.showMobile {
  display: none;
}

@media(max-width: 1200px) {
  .container {
    margin-top: 0px;
  }
  .hideMobile {
    display: none;
  }
  .showMobile {
    display: block;
  }
}
