.liveStreamLabel {
  font-size: 12px;
  color: #D7355C;
  text-decoration: underline;
  cursor: pointer;
}
.liveStreamLabel .icon {
  display: inline-block;
  width: 20px;
  position: relative;
  top: -5px;
  margin-right: 6px;
  float: none;
  margin-bottom: 0;
}
.loading {
  text-decoration: none;
  color: #0A0A0A;
  cursor: default;
}
.disable {
  cursor: default;
}