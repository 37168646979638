.info {
  color: #999999;
  font-size: 0.875rem;
}
.top {
  display: flex;
  padding: 2rem;
  justify-content: space-between;
}
.podcastName {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
}
.top p:first-of-type {
  margin-top: 0;
}
.frame {
  border: 1px var(--border-color) solid;
  border-radius: 0.5rem;
}
.container {
  margin: 0 auto;
  max-width: 620px;
  padding-top: 6rem;
  margin-bottom: 3rem;
}
.container h1 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
.spacer {
  min-width: 1px;
  min-height: 100%;
  margin: 0 2rem;
  background-color: var(--border-color);
}
.content {
  padding: 2rem;
  border-top: 1px var(--border-color) solid;
}
@media (max-width: 992px) {
  .top {
    flex-direction: column;
  }
  .spacer {
    margin: 1.3rem 0;
    width: 100%;
    height: 1px;
  }
  .container {
    padding-top: 2rem;
  }
}
