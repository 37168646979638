.input {
  margin-top: 20px;
  border: none;
}
.input input {
  min-width: 380px;
  border-radius: 40px !important;
  border: none !important;
  padding-left: 45px !important;
  background-image: url('../../img/icons/search.svg');
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 15px 50%;
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(33,17,71,0.1);
}

.input input:focus {
  opacity: 1;
  box-shadow: 0 0 20px rgba(33,17,71,0.7);
}

@media(max-width: 520px) {
  .input input {
    min-width: 280px;
  }
}
