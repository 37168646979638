.container {
  background-color: #fff;
  width: 100vw;
  left: 0;
  z-index: 999;
  position: fixed;
  bottom: 0;
  padding: 20px;
  display: flex;
  align-items: center;
}
.visir.container {
  background-color: #000;
}

.slider {
  position: absolute !important;
  left: 0;
  top: -8px;
}

.playBtn {
  cursor: pointer;
  width: 45px;
  height: 45px;
  background-image: url('../../img/icons/play-oval.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.visir .playBtn {
  background-image: url('../../img/icons/visir-play-oval.svg');
  width: 21px;
  height: 26px;
}

.playBtnPlaying {
  background-image: url('../../img/icons/pause-oval.svg');
}

.visir .playBtnPlaying {
  background-image: url('../../img/icons/visir-pause.svg');
}

.close {
  position: absolute;
  top: 0.875rem;
  right: 1rem;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url('../../img/icons/remove.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.innerContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.actionsContainer {
  display: flex;
  align-items: center;
}

.rewind,
.forwards {
  width: 25px;
  height: 25px;
  background-image: url('../../img/icons/15s.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 12px;
  cursor: pointer;
}
.visir .rewind,
.visir .forwards {
  width: 21px;
  height: 18px;
}

.forwards {
  background-image: url('../../img/icons/30s.svg');
  margin-right: 0;
  margin-left: 12px;
}
.visir .rewind {
  margin-right: 2.5rem;
  background-image: url('../../img/icons/10s-b.svg');
}
.visir .forwards {
  margin-left: 2.5rem;
  background-image: url('../../img/icons/10s-f.svg');
}

.time {
  font-size: 16px;
}
.title {
  max-width: 580px;
}
.titleContainer {
  margin-left: 20px;
  min-width: calc(100% - 40px);
  display: flex;
  align-items: center;
}
.img {
  margin-right: 1rem;
  overflow: hidden;
  border-radius: 0.5rem;
}

.podcastName {
  font-weight: 700;
  font-size: 22px;
}
.visir .podcastName {
  font-family: 'BerlingskeSans', sans-serif;
  font-weight: 800;
  color: #fff;
}
.visir .title {
  color: #999;
}
.visir .titleContainer {
  margin-left: 2rem;
}

.duration {
  position: absolute;
  right: 20px;
  color: #606060;
}
.visir .duration {
  color: #fff;
}
@media (max-width: 991px) {
  .title {
    max-width: 380px;
    font-size: 14px;
  }
}
@media (max-width: 640px) {
  .container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999999;
    user-select: none;
  }
  .title {
    max-width: 220px !important;
    word-break: break-word;
  }
  .innerContainer {
    position: relative;
    margin-top: 0.5rem;
  }
  .slider {
    position: relative !important;
    top: 0;
    left: 0;
    width: calc(100vw - 40px) !important;
  }
  .close {
    display: block;
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
    margin-bottom: 2.5rem;
    position: relative;
    text-align: center;
    background: none;
    font-size: 1.2rem;
    text-decoration: underline;
  }
  .visir .close {
    color: #fff;
  }
  .podcastName {
    font-size: 14px;
  }
  .mobileImg {
    text-align: center;
    margin-bottom: 4rem;
  }
  .mobileImg img {
    border-radius: 20px;
    max-height: 250px;
  }
  .title {
    max-width: 280px;
    font-size: 12px;
  }
  .duration {
    right: 0;
    top: -2.5rem;
    font-size: 12px;
  }
  .visir .rewind {
    margin-right: 1.5rem;
  }
  .visir .forwards {
    margin-left: 1.5rem;
  }
  .img {
    display: none;
  }
}
