* {
  box-sizing: border-box;
}

html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
}

html.with-modal {
  overflow-y: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
  font-size: 16px;
  line-height: 1.5;
  color: #0a0a0a;
  background-color: #fff;
}

h1,
p {
  padding: 0;
}

a:hover {
  text-decoration: none !important;
}

ul {
  padding-left: 1.5em !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0;
  list-style-type: disc !important;
}

p {
  margin-top: 1.5em !important;
  margin-bottom: 0;
}
.react-notification-root {
  position: fixed;
  z-index: 999999 !important;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

:root {
  --border-color: #cecece;
}
.slick-slide div {
  outline: none;
}
.slick-dots {
  z-index: 999;
}
.slick-arrow.slick-prev {
  left: 25px;
  z-index: 999;
}
.slick-arrow.slick-next {
  right: 25px;
  z-index: 999;
}
.slick-dots li {
  width: 3px !important;
  height: 20px !important;
}
.slick-dots {
  bottom: 25px !important;
}
.slick-dots button:before {
  color: #fff !important;
  font-size: 8px !important;
}
.slick-disabled {
  display: none !important;
}
.slick-arrow:before {
  font-size: 24px !important;
  opacity: .65 !important;
}
.slick-slider {
  margin-bottom: -8px;
}
:root {
  --border-color: #cecece;
  --purple-500: #211147;
  --purple-600: #1c0b39;
  --gray-500: #999999;
  --red-500: #f44e4e;
  --red-600: #f22f2f;
}
.rnc__base {
  top: 0;
}