.container {
  position: relative;
  display: inline-block;
}
.icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 50%;
}
.dots {
  background-image: url('../../img/icons/menu.svg');
  background-size: 15px 15px;
}

.dots:hover {
  background-color: #f4f4f4;
}

.dropdown {
  z-index: 999;
  position: absolute;
  top: 100%;
  right: -10px;
  background-color: #fff;
  padding: 8px 0;
  border: 1px #ededed solid;
  border-radius: 5px;
  user-select: none;
}

.dropdown a, .dropdown div {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  padding: 5px 30px;
  background-color: #fff;
  width: 100%;
  text-align: center;
}
.dropdown a, .dropdown a:hover {
  color: #0A0A0A;
}
.dropdown a:hover, .dropdown div:hover {
   background-color: #f4f4f4;
}
