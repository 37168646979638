.container {
  display: inline-flex;
  border: 1px #F1F1F7 solid;
  border-radius: 10px;
}

@media(max-width: 640px) {
  .container {
    display: block;
    max-width: 640px;
    overflow-x: scroll;
  }
}
