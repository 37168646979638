.showAllComments {
  font-weight: 500;
  cursor: pointer;
  color: #65676b;
  font-size: 14px;
}

.showAllComments:hover{
  text-decoration: underline;
}
