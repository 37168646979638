.description {
  clear:both;
  color: #535353;
  margin: 20px 0;
  line-height: 28px;
  white-space: pre-wrap;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
