.container {
  max-width: 640px;
  border: 1px #ddd solid;
  padding: 20px;
  border-radius: 20px;
  margin: 20px auto;
}

.container h2 {
  font-size: 22px;
  font-weight: 700;
}

.back {
  text-align: center;
  margin-bottom: 20px;
}
