.loader {
  margin-top: 20px;
  padding: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../img/icons/loader.svg');
}
.withoutSpace {
  margin-top: 0;
}
