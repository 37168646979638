.title {
  padding: 20px;
}

.red {
  color: #fff;
  font-size: 20px;
  background-color: #D7355C;
}

.green {
  color: #fff;
  font-size: 20px;
  background-color: #3ab97a;
}

.content {
  padding: 20px;
  text-align: center;
  font-size: 18px;
  color: #0A0A0A;
}

.actions {
  text-align: center;
}

.redText {
  color: #D7355C;
}

.greenText {
  color: #3ab97a;
}
