.container {
  margin-top: 1rem;
}

.selectFile {
  cursor: pointer;
  border: 1px dashed var(--border-color);
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
}

.selectFile:hover {
  background-color: #f8f8f8;
}

.hide {
  display: none;
}
