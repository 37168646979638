.label {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
}
.input {
  border: 1px #f1f1f1 solid;
  display: flex;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.input input {
  border: none;
  padding: 12px;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.input input.copiedPadding {
  padding-right: 90px;
  transition: all 0.2s ease-in-out;
}
.copyBtn {
  border: none;
  background-color: #6d6d6d;
  min-width: 90px;
  color: #fff;
}

.copyBtn:hover {
  background-color: #585858;
}

.copied{
  position: absolute;
  top: 50%;
  right: 110px;
  opacity: 0;
  transform: translateY(-50%);
  color: #3ab97a;
  font-weight: 600;
  animation: fadeIn 0.2s ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}