@font-face {
  font-family: 'BerlingskeSans';
  src: url('../../fonts/BerlingskeSans-XBd.woff2') format('woff2'),
    url('../../fonts/BerlingskeSans-XBd.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BerlingskeSans';
  src: url('../../fonts/BerlingskeSansCn-Bold.woff2') format('woff2'),
    url('../../fonts/BerlingskeSansCn-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BerlingskeSlab';
  src: url('../../fonts/BerlingskeSlab-XBd.woff2') format('woff2'),
    url('../../fonts/BerlingskeSlab-XBd.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BerlingskeSans';
  src: url('../../fonts/BerlingskeSans-Regular.woff2') format('woff2'),
    url('../../fonts/BerlingskeSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BerlingskeSans';
  src: url('../../fonts/BerlingskeSans-Md.woff2') format('woff2'),
    url('../../fonts/BerlingskeSans-Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.visir-container {
  --border-color: #cecece;
  --purple-500: #6a5ef0;
  --purple-600: #5b4eee;
  --gray-500: #999999;
  --red-500: #f44e4e;
  --red-600: #f22f2f;
}
.visir-container a {
  color: var(--purple-500);
}
.visir-container a:hover {
  color: var(--purple-600);
}
.row {
  --bs-gutter-x: 3.5rem;
}
