.container {
  margin-top: 10px;
}


.commentsText {
  color: #65676b;
  font-size: 15px;
  user-select: none;
}
.commentsText:hover {
  text-decoration: underline;
  cursor: pointer;
}
.commentsContent {
  border-top: 1px #ced0d4 solid;
  padding-top: 5px;
  margin-top: 5px;
}
.showAllComments {
  margin: 5px;
}
