.container {
  padding: 20px;
  max-width: 540px;
}

.checkbox {
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.checkbox label, .checkbox input {
  cursor: pointer;
}
.checkbox input {
  margin-right: 8px;
  position: relative;
  top: -4px;
}

.head {
  font-size: 22px;
  font-weight: 700;
}

.content {
  line-height: 28px;
}

.input {
  margin-top: 15px;
}

.action {
  margin-top: 20px;
}
