.picker label {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  transition: font-size 0.1s ease-in-out;
}

.picker label:focus-within {
  color: #170E33;
  font-size: 12px;
}
.pickerContainer {
  cursor: pointer;
}
