.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.label {
  color: #000;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}
.dropdown {
  padding: 10px 15px;
  display: block;
  border: 1px solid #efefef;
  background-color: #fafcfd;
  background-image: url('../../img/icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
  background-size: 15px;
  cursor: pointer;
  color: #b8c9d5;
}
.selected {
  color: #000;
  user-select: none;
}
.dropdownContainer {
  position: relative;
}

.dropdownList {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  width: 100%;
  border: 1px #efefef solid;
  border-top: none;
  top: 100%;
  left: 0;
  max-height: 250px;
  overflow-y: scroll;
  display: none;
  user-select: none;
}

.option {
  padding: 15px;
  border-bottom: 1px #efefef solid;
  cursor: pointer;
}
.optionSelected {
  background-color: #f6f6f6;
}

.option:last-of-type {
  border-bottom: none;
}
.option:hover {
  background-color: #f6f6f6;
}
.dropdownContainer.active .dropdownList {
  display: block;
}

.dropdownContainer.active .dropdown {
  background-image: url('../../img/icons/arrow-up.svg');
}

.dropdownContainer.active .label {
  color: blue;
  font-size: 12px;
}
