.container {
  position: relative;
}

.container audio {
  width: 100%;
  outline: none;
  position: relative;
}

.title {
  font-weight: 700;
  margin-bottom: 5px;
}

.edit {
  cursor: pointer;
  color: #5f5f5f;
  display: inline-block;
  position: relative;
  left: 5px;
  font-weight: 400;
  text-decoration: underline;
}

.edit:hover {
  color: #0a0a0a;
}
