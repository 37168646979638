.row {
  margin-top: 2rem;
}
.content {
  margin-top: 0.5rem;
  line-height: 1.675rem;
}
.title {
  margin-top: 3rem !important;
}
.container h4 {
  line-height: 2.2rem;
}
@media (max-width: 991px) {
  .container {
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
  .row {
    margin-top: 0;
  }
  .title {
    margin-top: 1.5rem !important;
  }
  .questionTitle {
    margin-top: 2rem !important;
  }
}
