.container textarea {
  width: 100%;
  border: none;
  resize: none;
  transition: all 0.1s ease-in-out;
  padding: 8px;
  color: #475259;
}


.container textarea:focus, .container.gray textarea {
  outline: none;
  background-color: #f2f2f2;
  border-radius: 4px;
}

.container.gray textarea {
  border-radius: 20px;
  padding: 8px 15px;
}

.disabled textarea {
  opacity: 0.4;
}
