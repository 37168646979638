.container {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .15);
  border-radius: 4px;
  margin: 0 0 16px;
  margin-top: 20px;
}
.visir.container {
  margin-top: 0;
  margin-bottom: 2rem;
  box-shadow:none;
  border: 1px var(--border-color) solid;
  border-radius: 0.5rem;
}

.top {
  padding: 15px 30px;
  border-bottom: 2px solid rgb(242, 242, 242);
  font-size: 18px;
  font-weight: 700;
  color: #0A0A0A;
}
.visir .top {
  border-bottom: 1px solid var(--border-color);
}

.content {
  padding: 15px 30px;
}

.uploadImageButton {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 32px;
  background-repeat: no-repeat;
  background-image: url('../../img/icons/feed-image-icon.svg');
  margin-bottom: 0;
}

.uploadImageButton:hover {
  background-position: 0 -20px;
  cursor: pointer;
}

.vimeoButton {
  display: inline-block;
  width: 24px;
  height: 20px;
  margin-right: 32px;
  background-image: url('../../img/icons/feed-vimeo-icon.svg');
  margin-bottom: 0;
  background-repeat: no-repeat;
  position: relative;
}

.vimeoButton:hover {
  background-position: 0 -20px;
  cursor: pointer;
}

.klippariButton {
  display: inline-block;
  width: 24px;
  height: 20px;
  margin-right: 32px;
  background-image: url('../../img/icons/feed-klippari-icon.svg');
  margin-bottom: 0;
  background-repeat: no-repeat;
  position: relative;
}

.klippariButton:hover {
  background-position: 0 -20px;
  cursor: pointer;
}

.uploadVideoButton {
  display: inline-block;
  width: 33px;
  height: 20px;
  margin-right: 30px;
  background-image: url('../../img/icons/feed-video-icon.svg');
  background-repeat: no-repeat;
  margin-bottom: 0;
}

.uploadVideoButton:hover {
  background-position: 0 -20px;
  cursor: pointer;
}
.showMobile {
  display: none;
}
.hideMobile {
  display: block;
}
.allowComments {
  position: relative;
  top: -4px;
  color: #586C78;
  font-weight: 500;
  user-select: none;
}
.allowComments input {
  position: relative;
  top: 1px;
}

.allowComments label {
  margin-left: 8px;
  cursor: pointer;
}

.bottom {
  display: flex;
  align-items: center;
  padding: 0 30px 16px 30px;
}
.progress {
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.hide {
  display: none;
}

@media(max-width: 540px) {
  .allowComments {
    margin-top: 10px;
    display: block;
  }
  .hideMobile {
    display: none;
  }
  .showMobile {
    display: block;
  }
}
