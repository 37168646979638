.manageCampaignNav {
  position: relative;
}
.manageCampaignNav a {
  position: relative;
  text-decoration: none;
  color: #454545;
  font-weight: 400;
  padding-bottom: 8px;
  display: inline-block;
  margin-right: 25px;
  z-index: 1;
}

.manageCampaignNav a:hover {
   color: #7d7d7d;
}

.manageCampaignNav a.active {
  color: #0A0A0A;
  font-weight: 700;
  border-bottom: 2px #5a5a5a solid;
}
.manageCampaignNav a.active:hover {
   color: #0A0A0A;
}

.dash {
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 1px #b5b5b5 dashed;
  bottom: 0;
  z-index: 0;
}
