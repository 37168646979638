.empty {
  text-align: center;
  margin-top: 60px;
}

.container h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 700;
  margin-top: 2rem;
}
.text {
  font-size: 22px;
  font-weight: 700;
  margin-top: 40px;
}

.empty img {
  display: inline-block;
  height: 350px;
}
