.threeDForm {
  width: 100%;
  height: 424px;
  border: none;
}
.mobilePaymentWrapper input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 15px 0;
}
