.titleCard {
  background-color: #fff;
  max-width: 560px;
  min-width: 560px;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.05);
}

.title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
}

@media(max-width: 640px) {
  .titleCard {
    min-width: 100vw;
  }
}
