.empty {
  text-align: center;
  margin-top: 60px;
}

.text {
  font-size: 22px;
  font-weight: 700;
  margin-top: 40px;
}

.empty img {
  display: inline-block;
  height: 350px;
  width: 100%;
}

.visir.empty {
  margin-top: 1rem;
}

@media(max-width: 520px) {
  .empty img {
    display: inline-block;
    height: 250px;
    width: 100%;
  }
  .text {
    font-size: 16px;
  }
  .empty {
    margin-top: 60px;
  }
}
