.container {
  background-color: #fff;
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  min-height: 215px;
  box-shadow: 0 0 20px 2px rgba(0,0,0,0.06);
}
.image {
  max-width: 240px;
  min-height: 100%;
  min-width: 240px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.title {
  font-size: 28px;
  font-weight: 700;
  color: #0A0A0A;
  text-decoration: none;
}

.description {
  color: #535353;
  line-height: 28px;
  margin-top: 10px;
  white-space: pre-wrap;
}

.actions {
  margin-top: 20px;
}


@media(max-width: 640px) {
  .container {
    flex-direction: column;
    max-width: 380px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .image {
    min-width: 100%;
    height: 280px;
    background-position: top;
  }
}
