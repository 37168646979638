.toggle {
  text-align: right;
}
.toggleContainer {
  text-align: right;
}

.comments {
  margin-top: 15px;
}

.toggleLabel {
  position: relative;
  top: -10px;
  left: -5px;
}

.keys {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px #f1f1f1 solid;
}
.obs {
  margin-bottom: 20px;
  text-align: center;
}
.isLiveText {
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  background-color: #3ab97a;
  padding: 20px;
}
.toggleComments {
  text-align: right;
}

.toggleLabel {
  position: relative;
  top: -10px;
  left: -5px;
}
.resetComments {
  position: relative;
  top: -10px;
  left: -5px;
  margin-left: 1.5rem;
}