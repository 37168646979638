.container {
  font-family: 'BerlingskeSans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.container p {
  line-height: 24px;
}
.container h1,
.container h2,
.container h3,
.container h4,
.container h5,
.container h6 {
  font-family: 'BerlingskeSlab';
  font-weight: 800;
  margin-bottom: 0;
  margin-top: 0;
  margin: 0;
}
.container strong {
  font-weight: 800;
}
