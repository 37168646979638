.input {
  margin-top: 1.2rem;
}
.checkboxInput {
  margin-top: 1.5rem;
}
.submit {
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.submitRight {
  justify-content: flex-end;
}
