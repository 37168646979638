.title {
  font-weight: 700;
}

.description {

}

.playlist {
  margin-top: 20px;
  max-height: 660px;
  border-radius: 15px;
  overflow-y: scroll;
  border: 1px #E6E6E6 solid;
}
.toggleCollapse {
  padding: 5px 12px;
  background-color: #fff;
  color: #0A0A0a;
  text-decoration: underline;
  border: none;
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
}
.playlist.single .episodeItem {
  border-radius: 10px;
}
.showAll {
  text-align: center;
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #0A0A0a;
}

.episodeItem {
  background-color: #F6F6F6;
  border-bottom: 1px #E6E6E6 solid;
  padding: 10px 20px 10px 60px;
  background-repeat: no-repeat;
  background-position: 25px 50%;
  cursor: pointer;
  background-size: 12px;
  background-image: url('../../img/icons/play-arrow.svg');
  margin-bottom: 0px;
  user-select: none;
}
.episodeItem:last-of-type {
  border-bottom: none;
}
.episodeItem:first-of-type {
  border-radius: 10px 10px 0 0 ;
}

.episodeItem:last-of-type {
  margin-bottom: 0;
  border-radius: 0 0 10px 10px;
}

.playing {
  background-image: url('../../img/icons/pause.svg');
}

.locked {
  cursor: auto;
  padding-left: 20px;
  background-image: url('../../img/icons/padlock.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
}

.episodeName {
  font-weight: 700;
}
