.container {
  padding: 2rem;
  min-height: 140px;
  position: relative;
}
.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  background-color: transparent;
  background-image: url('../../img/icons/close.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  padding-left: 22px;
  background-position: 0 4px;
}
