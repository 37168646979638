.info {
  font-size: 12px;
  padding-top: 5px;
  padding-left: 5px;
}

.monthsContainer {
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
}

.monthItem {
  border: 1px #ddd solid;
  background-color: #fff;
  padding: 2px 10px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.monthItem:hover {
  background-color: #eeeeee;
}

.monthItem.active {
  background-color: #E9E8ED;
  color: #0a0a0a;
  border: 1px #818181 solid;
  font-weight: 700;
}
