.container {
  min-height: 440px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/header-bg.jpeg');
  background-position: 50% -260px;
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.7;
  z-index: 1;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  color: #fff;
  font-size: 44px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  padding: 0 20px;
  padding-top: 40px;
}

.description {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  max-width: 780px;
  text-align: center;
  margin-top: 10px;
}

.bold {
  font-weight: 700;
}

@media (max-width: 640px) {
  .container {
    min-height: 380px;
  }
  .title {
    font-size: 28px;
  }
  .description {
    display: none;
  }
}

@media(max-width: 1200px) {
  .container {
    background-position: center;
  }
}
