h2.feedTitle {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}
.postContainer {
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px var(--border-color) solid;
}
.top {
  min-height: 320px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.lock {
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: #d4d4d3;
  background-position: center;
  background-image: url('../../img/icons/lock.svg');
  background-repeat: no-repeat;
}
.title {
  font-size: 1.5rem;
  color: #fff;
  margin-top: 0.625rem;
  font-weight: 800;
}
.overlay {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.topContent {
  z-index: 0;
  text-align: center;
  color: #fff;
}
.btn {
  margin-top: 1.5rem;
}
.content {
  padding: 1rem 1.5rem;
  position: relative;
}
.date {
  color: #999999;
}
.seeMore {
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
}
.readMore {
  margin-top: 0.875rem;
}
.blurContent {
  min-height: 2.5rem;
  position: absolute;
  left: 1.5rem;
  top: 2.8rem;
  width: calc(100% - 3rem);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
