.checkboxInput {
  margin-top: 1.5rem;
}
.submit {
  margin-top: 1.5rem;
}
@media (max-width: 991px) {
  .formContainer {
    margin-top: -1.2rem;
  }
  .input {
    margin-top: 1.2rem;
  }
}
