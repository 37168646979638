.payment {
  padding: 20px;
}
.title {
  font-size: 22px;
  font-weight: 600;
}
.actions {
  margin-top: 20px;
}
.description {
  margin-bottom: 20px;
}
.threeDForm {
  width: 100%;
  height: 424px;
  border: none;
}
.mobilePaymentWrapper input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 15px 0;
}