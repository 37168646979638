.container {
  margin: 12px 0;
}
.content {
  max-width: 100%;
  display: inline-block;
  background-color: #f1f2f5;
  border-radius: 18px;
  padding: 8px 12px;
  white-space: pre-wrap;
}

.textContent {

}

.name {
  font-size: 13px;
  color: #050505;
  font-weight: 700;
}

.footer {
  margin-left: 12px;
  color: #65676b;
  font-size: 12px;
  margin-top: 5px;
}

.delete {
  font-weight: 400;
  margin-left: 5px;
  color: #D7355C;
}

.delete:hover {
  cursor: pointer;
  text-decoration: underline;
}
