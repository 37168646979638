.subscriptionPlanItem {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

.subscriptionPlanItem div {
  width: 100%;
}

.subscriptionPlanItem div + div {
  margin-left: 20px;
}

.delete, .add {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../img/icons/minus.svg');
  cursor: pointer;
  position: absolute;
  top: calc(50% + 6px);
  left: -8px;
  transition: transform 0.2s ease-in-out;
}

.newPlan {
  text-decoration: underline;
  text-align: center;
  margin-bottom: 20px;
}
.newPlan span {
  color: #4a4a4a;
  cursor: pointer;
}
.newPlan span:hover {
  color: #0A0A0A;
}
.add {
  background-image: url('../../img/icons/plus.svg');
  left: 0;
}

.delete:hover, .add:hover {
  transform: scale(1.05);
}

.itemActions {
  position: relative;
  max-width: 40px;
}
