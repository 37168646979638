.container {
  margin-bottom: 2.5rem;
}
.container:last-of-type {
  margin-bottom: 0;
}
.title {
  margin-bottom: 1.5rem !important;
}
.description {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}
.container h2 {
  font-size: 1.5rem;
}
.isLocked {
  background-image: url('../../img/icons/lock.svg');
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 18px;
}
@media(max-width: 991px) {
  .container {
    margin-bottom: 1.5rem;
  }
}
