.container input[type='text'],
.container input[type='number'],
.container input[type='password'] {
  width: 100%;
  border-radius: 0.25rem;
  background-color: #f0f0f0;
  border: none;
  padding: 1rem;
}
.container input:focus {
  outline-color: var(--purple-500);
}
.container label {
  margin-bottom: 0.5rem;
}
.container input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f0f0f0;
  border-radius: 0.25rem;
}
.checkmarkText {
  position: relative;
  left: 35px;
}
.label {
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.error {
  color: var(--red-500);
  font-size: 0.875rem;
  font-weight: 700;
  margin-top: 0.5rem;
}
/* On mouse-over, add a grey background color */
/*.container:hover input ~ .checkmark {*/
/*  background-color: #ccc;*/
/*}*/

.container input:checked ~ .checkmark {
  background-color: var(--purple-600);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 6px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
