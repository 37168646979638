.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.onlyText {
  width: 100%;
  text-align: center;
}
