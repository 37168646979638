.commentsList {
  padding: 10px 0 30px;
  margin-top: 20px;
  border-top: 1px #f1f1f1 solid;
}

.commentsList.empty {
  text-align: center;
}

.loadMoreBtn {
  text-align: center;
  margin: 20px 0;
}
.loadMoreBtn button {
  justify-content: center;
  margin: 0 auto;
}

.empty {
  margin-top: 20px;
}