.top {
  text-align: center;
  margin: 20px 0;
}
.content {
  margin: 20px 0;
}
.button {
  margin: 20px 0;
  text-align: center;
}