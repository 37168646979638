.textarea {
  width: 100%;
  height: 120px;
  border: 1px #CDCDCD solid;
  resize: none;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
  font-family: 'Inter', sans-serif;
  background-color: #fafcfd;
  font-size: 16px;
}

.textarea:focus {
  outline: none;
  border: 1px #170E33 solid;
}
.areaContainer {
  display: block;
}

.container {

}

.label {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  transition: font-size 0.1s ease-in-out;
}
