.container {
  border-top: 1px #DADADA solid;
  padding-top: 30px;
  margin-top: 30px;
  text-align: center;
}

.image {
  margin-top: 30px;
}

.image img {
  width: 100%;
  max-width: 540px;
}
