.textInput input {
  width: 100%;
  height: 45px;
  display: block;
  background-color: #fafcfd;
  padding-left: 15px;
  font-size: 16px;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.textInput input:hover {
  border-color: #a7b8d1;
}

.textInput input:focus {
  outline: none;
  border: 1px solid #170E33;
}

.textInput input::placeholder {
  border-color: #a7b8d1;
  font-size: 16px;
}

.textInput label {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  transition: font-size 0.1s ease-in-out;
}

.textInput label:focus-within {
  color: #170E33;
  font-size: 12px;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.postfix {
  margin: 0 10px;
  margin-left: 5px;
  color: #878787;
}
