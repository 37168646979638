.container {
  padding: 20px;
}

.container ol {
  margin-bottom: 0;
  padding-left: 30px;
}

.container ol li {
  margin: 10px 0;
}