.container {
  background-color: var(--red-600);
  color: #fff;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999999;
  text-align: center;
  animation: slideDown 0.5s ease-in-out forwards;
}
.link {
  color: #fff !important;
}
.link:hover {
  text-decoration: underline !important;
}
@keyframes slideDown {
  0% {
    margin-top: -180px;
  }
  100% {
    margin-top: 0;
  }
}
