.analyticsItem {
  border: 1px #ddd solid;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  text-align: center;
  margin: 10px;
  width: 100%;
}
.number {
  font-size: 48px;
}
.rowContainer {
  display: flex;
}

.periodSelector {
  max-width: 350px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.activeSubscriptions {
  text-align: center;
  margin-top: 15px;
}
@media(max-width: 992px) {
  .rowContainer {
    flex-wrap: wrap;
  }
}
