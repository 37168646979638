.image {
  display: block;
  min-height: 120px;
  width: 180px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.title {
  display: flex;
  align-items: center;
}
.title img {
  float: left;
  margin-right: 20px;
  width: 150px;
}
.name {
  display: block;
  line-height: 44px;
  font-size: 38px;
  margin-bottom: 15px !important;
  font-weight: 700;
}

@media(max-width: 920px) {
  .title {
    display: block;
  }
  .title img {
    float: none;
    display: inline-block;
    margin-bottom: 20px;
  }
}
