.top {
  margin-bottom: 2rem;
  height: 400px;
  text-align: center;
  background-color: #f2f2f2;
  color: #fff;
  font-size: 4em;
  font-weight: 800;
}
.title {
  min-height: 100%;
  display: flex;
  align-items: center;
}
.topEmpty {
  background-color: #000;
  height: 100%;
}
@media(max-width: 991px) {
  .title {
    justify-content: center;
  }
  .top {
    height: 320px;
  }
}
