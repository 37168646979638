.postContainer {
  margin-bottom: 1.5rem;
}
.top {
  min-height: 320px;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.lock {
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: #d4d4d3;
  background-position: center;
  background-image: url('../../img/icons/lock.svg');
  background-repeat: no-repeat;
}
.title {
  font-size: 1.5rem;
  color: #fff;
  margin-top: 0.625rem;
  font-weight: 800;
}
.overlay {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.topContent {
  z-index: 0;
  text-align: center;
  color: #fff;
}
.btn {
  margin-top: 1.5rem;
}
.content {
  padding: 1rem 1.5rem;
  border: 1px var(--border-color) solid;
  border-radius: 0 0 0.5rem 0.5rem;
  position: relative;
  border-top: none;
}
.content.noAttachment {
  border-top: 1px var(--border-color) solid;
  border-radius: 0.5rem;
}
.meta {
  display: flex;
  justify-content: space-between;
  color: #999999;
}
.contentText {
  margin-top: 10px;
  white-space: pre-line;
}
.seeMore {
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
}
.readMore {
  margin: 0.5rem 0;
  text-decoration: underline;
  color: var(--purple-500);
  z-index: 1;
  position: relative;
}
.removeFeed {
  color: var(--red-500);
}
.removeFeed:hover {
  cursor: pointer;
  color: var(--red-600);
  text-decoration: underline;
}
.readMoreLocked {
  margin-bottom: 0;
}

.readMore:hover {
  cursor: pointer;
  color: var(--purple-600);
}
.blurredContent {
  position: relative;
}
.blurContent {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
