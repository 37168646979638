.container {
  width: 100%;
}

.container > div {
  display: block;
  padding: 10px;
}
.container table {
  width: 100%;
}
.container tbody strong {
  color: #0A0A0A;
}
.container tbody tr:nth-of-type(2n) td {
  background-color: #F5F6FA;
}

.container tbody td {
  padding: 15px;
  position: relative;
  max-width: 450px;
}

@media(max-width: 640px) {
  .container table {
    min-width: 640px;
  }
}
