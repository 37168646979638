.container {
  padding: 20px;
  margin-bottom: 40px;
  min-height: calc(100vh - 480px);
}

.title {
  padding: 20px;
  font-weight: 700;
  font-size: 22px;
}

.title span {
  font-weight: 400;
  color: #878787;
}

@media(max-width: 640px) {
  .title {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
  }
}
