.container {
  display: flex;
  padding: 40px 0;
}
.isPlaying {
  padding-bottom: 140px !important;
}

.left, .right {
  min-width: 50%;
}

.noEpisodes {
  max-width: 720px;
  margin: 0 auto;
}
.right {
  padding-left: 80px;
}
.hideDesktop {
  display: none;
}

.hideMobile {
  display: block;
}
@media(max-width: 1199px) {
  .left, .right {
    width: 100%;
  }
  .right {
    margin-top: 20px;
    padding-left: 0;
  }
  .hideDesktop {
    display: block;
  }
  .hideMobile {
    display: none;
  }
  .right {
    order: 1;
  }
  .left {
    order: 2;
    margin-top: 20px;
  }
  .container {
    padding: 20px 0;
    flex-direction: column;
  }
}
