.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 99999;
}

.container {
  min-width: 540px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 80vh;
  border-radius: 5px;
  transform: translateX(-50%) translateY(-50%);
}
.hideOverflow {
  overflow-y: scroll;
}

@media (max-width: 540px) {
  .container {
    min-width: calc(100% - 40px);
    border-radius: 0;
  }
}
