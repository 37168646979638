.button {
  text-align: right;
}

.search {
  margin: 10px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
}

.searchInput {
  flex: 1;
  margin-right: 10px;
}

.searchBtn {
}

.content {

}
