.container {
  cursor: pointer;
  text-align: center;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
}

.container + .container {
  margin-left: 5px;
}

.container:hover:after {
  content: '';
  top: -10px;
  left: -10px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f5ebec;
  z-index: 1;
  opacity: 0;
  transform: scale(0);
  animation: fadeIn 0.1s ease-in-out forwards;
  border-radius: 100%;
}

.container img {
  display: inline-block;
  height: 100%;
  z-index: 2;
  position: relative;
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 2;
  transform: translateX(-50%) translateY(-50%);
  user-select: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 0.4;
    transform: scale(1);
  }
}
