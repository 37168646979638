.container {
  color: #fff;
  background-color: #000;
}
.top {
  height: 60px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.top img {
  display: inline-block;
  cursor: pointer;
}
.nav {
  display: flex;
  border-top: 1px #262626 solid;
  padding: 0 1.5rem;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.left a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  padding: 1.5rem 0;
  font-size: 0.875rem;
  display: inline-block;
  text-decoration: none;
  position: relative;
  margin-right: 1rem;
}
.left a:hover {
  color: #fff;
}
.left a.active:after {
  content: '';
  width: 100%;
  background-color: var(--purple-500);
  height: 2px;
  bottom: 0;
  position: absolute;
  left: 0;
}

.top a {
  color: #fff;
  text-decoration: none;
}
.top a:hover {
  color: #fff;
}
.arrow {
  display: inline-block;
  width: 16px;
  height: 12px;
  background-image: url('../../img/icons/arrow-left.svg');
  margin-right: 1rem;
}
.profile {
  font-weight: 800;
  text-transform: uppercase;
}
.profile button {
  margin-left: 2rem;
}
@media (max-width: 720px) {
  .top a {
    display: none;
  }
  .profileName {
    display: none;
  }
  .left a {
    font-size: 12px;
  }
  .profile button {
    font-size: 12px !important;
    margin-left: 0;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
  }
}
