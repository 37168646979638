.container {
  margin-top: 40px;
}
.info {
  font-size: 12px;
  padding-top: 5px;
  padding-left: 5px;
}

.stream {
  margin-top: 20px;
  display: flex;
}

.stream, .stream iframe {
  min-height: calc(80vh - 142px);
}
.comments {
  margin-top: 20px;
}

.left {
  display: flex;
}

.right {
  flex-grow: 1;
  margin-left: 80px;
}
.emptyScreen {
  padding-top: 40px;
}
.visir .emptyScreen {
  padding-top: 0;
  background-color: #000;
  color: #fff;
  border-radius: 0.5rem;
  font-weight: 800;
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visir .containerWithoutComments, .visir .containerWithoutComments iframe {
  min-height: calc(80vh - 150px);
  padding-bottom: 40px;
}
.visir .containerWithComments, .visir .containerWithComments iframe {
  min-height: calc(80vh - 342px);
}
@media(max-width: 640px) {
  .visir .emptyScreen {
    font-size: 1.2rem;
  }
}
