.container {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 2em;
  margin-bottom: 2rem;
  max-width: 780px;
}
.container h2 {
  font-size: 1.5em;
}
