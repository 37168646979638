.container {
  margin-top: 20px;
}

.container h1 {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 700;
}

.link {
  color: #007bff;
  cursor: pointer;
}

.link:hover {
  color: #0056b3;
}

.info {
  margin-bottom: 20px;
  margin-top: -10px;
}
